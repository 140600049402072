import { useCallback, useEffect, useRef } from "react";
import { navigate } from "@reach/router";
import {usePath} from 'hookrouter';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function usePathToNavigateBack() {
  let link = "/";
  const backToHome = useCallback(() => {
    navigate(link);
  }, [link]);
  return backToHome;
}

export function usePathToGenerateBackLink() {
  const path = usePath();
  let link = path === "/" ? "https://www.porowneo.pl/" : '/';
  return link;
}

export const toLeasingPointFormatCurrency = (value) => {
  let numb =
    value
      .toLocaleString("en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      .split(",")
      .join(" ")
      .split(".")
      .join(",") + " ZŁ";
  return numb;
};

export const valuesToUpperCase = (valuesObject) => {
  const values = {};
  for (const key in valuesObject) {
    const value = valuesObject[key];
    if (
      typeof valuesObject[key] === "string" &&
      value !== "true" &&
      value !== "false"
    ) {
      values[key] = value.toUpperCase();
    } else {
      values[key] = value;
    }
  }
  return values;
};
